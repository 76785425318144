<template>
<div id="Events">

<div>
  <div class="pa-5 secondary elevation-5 bg-accent-card">
    <p class="mt-5 display-1">Events
      <v-btn color="accent" style="margin-top: -15px" @click="$router.push('/addevent')" fab dark absolute right >
        <v-icon> add </v-icon>
      </v-btn>
    </p>
  </div>
</div>  

<datatablevue :tableData="tableData"></datatablevue>

</div>
</template>

<script>
const datatablevue = () => import(/* webpackChunkName: "datatablevue" */ '../../components/shared/dataTable');



export default {

    name: 'Events',

    components: {
        datatablevue
    },

    data(){return{

        // table headers
        headers: [ 
            { text: 'Date Of Event', value: 'DOE' } ,
            { text: 'Status', value: 'status' } ,
            { text: 'Title', value: 'title' }, 
        ],

    }},

    computed: {

        GetEvents(){
            return this.$store.getters.GetAllEvents;
        },

        tableData(){
            return {'headers': this.headers, 'Data': this.GetEvents}
        },

    },

}
</script>
